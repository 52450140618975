import { FC, useEffect } from 'react';
import { useRouter } from 'next/router';

import { initGACosmetology, logPageViewCosmetology } from './analiticsForCosmetology';
import { initGAHairMakeUp, logPageViewHairMakeUp } from './analiticsForHairMakeUp';
import { initGAPermanent, logPageViewPermanent } from './analiticsForPermanent';
import { initGANails, logPageViewNails } from './analiticsForNails';
import { initCommonGA, logCommonPageView } from './analyticsGeneral';

const isBrowser = () => typeof window !== 'undefined';

const isLocal = () => window.location.hostname === 'localhost';

const isDev = () => process.env.PROBHUB_MODE === 'development';

export const GAProvider: FC<any> = ({ children }) => {
  const router = useRouter();

  useEffect(() => {
    // @ts-expect-error
    const shouldTrack = !isLocal() && !isDev() && isBrowser() && !window.GA_INITIALIZED;

    if (shouldTrack) {
      initCommonGA();
      if (router.asPath.includes('/cosmetology')) {
        initGACosmetology();
        logPageViewCosmetology();
      } else if (router.asPath.includes('/permanent')) {
        initGAPermanent();
        logPageViewPermanent();
      } else if (router.asPath.includes('/nails')) {
        initGANails();
        logPageViewNails();
      } else if (router.asPath.includes('/hairmakeup')) {
        initGAHairMakeUp();
        logPageViewHairMakeUp();
      }
      logCommonPageView();
    }
  }, []);

  useEffect(() => {
    router.events.on('routeChangeComplete', () => {
      if (router.asPath.includes('/cosmetology')) {
        logPageViewCosmetology();
        logCommonPageView();
      } else if (router.asPath.includes('/permanent')) {
        logPageViewPermanent();
        logCommonPageView();
      } else if (router.asPath.includes('/nails')) {
        logPageViewNails();
        logCommonPageView();
      } else if (router.asPath.includes('/hairmakeup')) {
        logPageViewHairMakeUp();
        logCommonPageView();
      } else {
        logCommonPageView();
      }
    });
    return () => {
      router.events.off('routeChangeComplete', () => {
        if (router.asPath.includes('/cosmetology')) {
          logPageViewCosmetology();
          logCommonPageView();
        } else if (router.asPath.includes('/permanent')) {
          logPageViewPermanent();
          logCommonPageView();
        } else if (router.asPath.includes('/nails')) {
          logPageViewNails();
          logCommonPageView();
        } else if (router.asPath.includes('/hairmakeup')) {
          logPageViewHairMakeUp();
          logCommonPageView();
        } else {
          logCommonPageView();
        }
      });
    };
  }, [router.events]);

  return <>{children}</>;
};
